<template>
  <div class="d-flex flex-column align-items-center">
    <template>
      <img
        src="https://apiserver.com.br/image/uploads/colmeia/3.svg"
        alt=""
        style="width: 250px"
      />
      <b-card class="px-4" style="width: 80%">
        <template v-if="!sent">
          <b-row>
           
           
            <b-col md="6">
              <label>Selecione a aplicação/produto:</label>
              <b-form-select
                v-model="model.application_id"
                :options="[
                  { text: 'Colmeia', value: 'f504361b-f730-4748-ba63-c6dc4a2b94ee' },
                  { text: 'Omin', value: '96fb1309-e4a0-40cf-b514-04ea38b6a8bf' },
                  { text: 'GTW', value: '51215f24-d140-11ec-9b24-8738b5b9fcc3' },
                  { text: 'Consumer', value: 'f98831d7-4f8f-4635-bd06-11586e852415' },

                ]"
              ></b-form-select>
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col class="d-flex flex-column justify-content-start">
              <label>Razão Social:</label>
              <b-input v-model="model.company_name"></b-input>
            </b-col>
            <b-col class="d-flex flex-column justify-content-start">
              <label>Nome Fantasia:</label>
              <b-input v-model="model.commercial_name"></b-input>
            </b-col>
            <b-col md="2">
              <label>Matriz/Filial:</label>
              <b-form-select
                v-model="model.matrix"
                :options="[
                  { text: 'Matriz', value: '0' },
                  { text: 'Filial', value: '1' },
                ]"
              ></b-form-select>
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col md="3">
              <label>Contato (nome)</label>
              <b-input v-model="model.contact_name"></b-input>
            </b-col>
            <b-col md="3">
              <label>Contato (telefone)</label>
              <b-input v-model="model.cell_phone"></b-input>
            </b-col>
            <b-col>
              <label>Contato (email)</label>
              <b-input v-model="model.email"></b-input>
            </b-col>
          </b-row>
          <b-row class="d-flex mt-4 justify-content-end">
            <b-button variant="primary" @click="save_lead">Salvar</b-button>
          </b-row>
        </template>
        <template v-else>
          <span class="lead">Formulário Enviado com sucesso!</span>
          <SweetalertIcon icon="success" />
        </template>
      </b-card>
      <b-alert v-if="success" class="mt-2" show variant="success">{{
        success
      }}</b-alert>
      <b-alert v-if="error" class="mt-2" show variant="danger">{{
        error
      }}</b-alert>
    </template>
  </div>
</template>

<script>
import SweetalertIcon from "vue-sweetalert-icons";
export default {
  data() {
    return {
      sent: false,
      id: "",
      password: "",
      confirm_password: "",
      error: "",
      success: "",
      model: {
        application_id: "f504361b-f730-4748-ba63-c6dc4a2b94ee",
        commercial_name: "",
        company_name: "",
        matrix: "",
        segment_id: "4",
        contact_name: "",
        cell_phone: "",
        email: "",
        commercial_name_unity: "",
        company_name_unity: "",
        cnpj: "",
        state: "",
        city: "",
        code: "",
        district: "",
        street: "",
        number: "",
        complement: ""
      },
    };
  },
  components: {
    SweetalertIcon,
  },
  methods: {
    async save_lead() {
      const response = await this.$store.dispatch("lead/save_lead", this.model);
      if (response) {
        this.sent = true;
      }      
    }    
  },
  mounted() {    
    const id = this.$route.params.user_id;
    if (id) {
      this.id = id;
    }
  },
};
</script>

<style>
body {
  background-color: #efeeea;
}
</style>