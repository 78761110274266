var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column align-items-center"},[[_c('img',{staticStyle:{"width":"250px"},attrs:{"src":"https://apiserver.com.br/image/uploads/colmeia/3.svg","alt":""}}),_c('b-card',{staticClass:"px-4",staticStyle:{"width":"80%"}},[(!_vm.sent)?[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('label',[_vm._v("Selecione a aplicação/produto:")]),_c('b-form-select',{attrs:{"options":[
                { text: 'Colmeia', value: 'f504361b-f730-4748-ba63-c6dc4a2b94ee' },
                { text: 'Omin', value: '96fb1309-e4a0-40cf-b514-04ea38b6a8bf' },
                { text: 'GTW', value: '51215f24-d140-11ec-9b24-8738b5b9fcc3' },
                { text: 'Consumer', value: 'f98831d7-4f8f-4635-bd06-11586e852415' },

              ]},model:{value:(_vm.model.application_id),callback:function ($$v) {_vm.$set(_vm.model, "application_id", $$v)},expression:"model.application_id"}})],1)],1),_c('b-row',{staticClass:"mt-4"},[_c('b-col',{staticClass:"d-flex flex-column justify-content-start"},[_c('label',[_vm._v("Razão Social:")]),_c('b-input',{model:{value:(_vm.model.company_name),callback:function ($$v) {_vm.$set(_vm.model, "company_name", $$v)},expression:"model.company_name"}})],1),_c('b-col',{staticClass:"d-flex flex-column justify-content-start"},[_c('label',[_vm._v("Nome Fantasia:")]),_c('b-input',{model:{value:(_vm.model.commercial_name),callback:function ($$v) {_vm.$set(_vm.model, "commercial_name", $$v)},expression:"model.commercial_name"}})],1),_c('b-col',{attrs:{"md":"2"}},[_c('label',[_vm._v("Matriz/Filial:")]),_c('b-form-select',{attrs:{"options":[
                { text: 'Matriz', value: '0' },
                { text: 'Filial', value: '1' },
              ]},model:{value:(_vm.model.matrix),callback:function ($$v) {_vm.$set(_vm.model, "matrix", $$v)},expression:"model.matrix"}})],1)],1),_c('b-row',{staticClass:"mt-4"},[_c('b-col',{attrs:{"md":"3"}},[_c('label',[_vm._v("Contato (nome)")]),_c('b-input',{model:{value:(_vm.model.contact_name),callback:function ($$v) {_vm.$set(_vm.model, "contact_name", $$v)},expression:"model.contact_name"}})],1),_c('b-col',{attrs:{"md":"3"}},[_c('label',[_vm._v("Contato (telefone)")]),_c('b-input',{model:{value:(_vm.model.cell_phone),callback:function ($$v) {_vm.$set(_vm.model, "cell_phone", $$v)},expression:"model.cell_phone"}})],1),_c('b-col',[_c('label',[_vm._v("Contato (email)")]),_c('b-input',{model:{value:(_vm.model.email),callback:function ($$v) {_vm.$set(_vm.model, "email", $$v)},expression:"model.email"}})],1)],1),_c('b-row',{staticClass:"d-flex mt-4 justify-content-end"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.save_lead}},[_vm._v("Salvar")])],1)]:[_c('span',{staticClass:"lead"},[_vm._v("Formulário Enviado com sucesso!")]),_c('SweetalertIcon',{attrs:{"icon":"success"}})]],2),(_vm.success)?_c('b-alert',{staticClass:"mt-2",attrs:{"show":"","variant":"success"}},[_vm._v(_vm._s(_vm.success))]):_vm._e(),(_vm.error)?_c('b-alert',{staticClass:"mt-2",attrs:{"show":"","variant":"danger"}},[_vm._v(_vm._s(_vm.error))]):_vm._e()]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }